import React, { useState } from 'react';
import axios from 'axios';

const ChatComponent = () => {
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      // Specify the server's host and port explicitly.
      const response = await axios.post('http://localhost:3001/get_answer', { question });
      setAnswer(response.data.answer);
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <input 
          type="text"
          value={question}
          onChange={e => setQuestion(e.target.value)}
        />
        <button type="submit">Ask</button>
      </form>
      <div>{answer}</div>
    </div>
  );
};

export default ChatComponent;
