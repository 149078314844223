import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hi Everyone, I am <span className="purple">Casey Tunturi </span>
            from <span className="purple"> Oregon, USA.</span>
            <br /> I am a former business owner and IT Specalist accomplished in a plethora of skills. I have been flown from Miami, NYC, L.A, San Fransico and more for my expertise. 
            <br />
            <br />
            On such a trip I had the pleasure of plugging in<span className="purple"> Snoop Dawg's Xbox,</span> and protecting high level <span className="purple">Health</span> and <span className="purple">Insurance</span> companies alongside MoxFive.
            <br />
            <br />
            Apart from coding, some other activities that I love to do!
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> Research Technology
            </li>
            <li className="about-activity">
              <ImPointRight /> OpenSource Industry
            </li>
            <li className="about-activity">
              <ImPointRight /> I mean... Games
            </li>
            <li className="about-activity">
              <ImPointRight /> Writing Tech Blogs
            </li>
            <li className="about-activity">
              <ImPointRight /> Travelling
            </li>
          </ul>

          <p style={{ color: "rgb(155 126 172)" }}>
            "Strive to build things that make a difference!"{" "}
          </p>
          <footer className="blockquote-footer">Casey</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
